import {appendOrdinal} from "../../utils/TimeConverter";

export const enStrings = {
    appTitle: "Kura Sushi - Rostering",

    //NavBar
    navDrawerCreateSchedule: "Templates",
    navDrawerBatchOptimise: "Optimise",
    navDrawerSettings: "Settings",
    navDrawerUserAccount: "Account",
    navDrawerSystemSettings: "System",
    navDrawerRestaurants: "Restaurants",
    navDrawerRestaurant: "Restaurant",
    navDrawerUsers: "Users",
    navDrawerStaff: "Staff",
    navDrawerLogout: "Logout",
    navDrawerCalendar: "Schedules",
    navDrawerShifts: "Shifts",
    navDrawerAvailability: "Availability",
    navDrawerTraining: "Training",
    navDrawerTrainingSetup: "Training Setup",
    navDrawerRosteredStaff: "Rostered Staff",
    navDrawerDailyDuties: "Daily Duties",
    navDrawerDeadlineSetup: "Deadlines",

    // Filters
    regionManagerFilterLabel: "RMG",
    areaManagerFilterLabel: "AMG",
    supervisorFilterLabel: "SV",
    locationFilterLabel: "Location",
    stationFilterLabel: "Station",
    filterItemOther: "Other",
    filterItemAll: "Select All",

    //General GQL Errors
    gqlErrorAuth: "You are not authorised to perform this action.",
    gqlErrorUnknown: "An error occurred. Please try again, or contact the support team.",
    generalUnknownError: "An error occurred. Please try again, or contact the support team.",
    gqlDuplicateEmailError: "A staff member with this email address already exists. Please check and try again.",
    gqlDuplicateIdError: (id) => `A staff member with ID ${id} already exists. Please check and try again.`,
    gqlDuplicateIdGeneralError: `A staff member with this ID already exists. Please check and try again.`,
    pageErrorNetworkError: "A network error has occurred. Please try again, or contact the support team.",
    notAvailableError: "The app is currently unavailable. Please try again, or contact the support team.",
    missingTaskOrPositionError: "All stations must have at least one task and one position associated with them",
    existingTemplateError: "A template with this name already exists",
    breakLengthTooShortError: (employeeName, breakMinutes) => `There is a shift requiring more than ${breakMinutes} minutes of break time for ${employeeName}`,
    fieldExactLengthError: (name, number) => `The field ${name} must have ${number} characters`,
    fieldTooLongError: (name, number) => `The field "${name}" must have at most ${number} character${number === 1 ? "" : "s"}`,
    fieldTooShortError: (name, number) => `The field "${name}" must have at least ${number} character${number === 1 ? "" : "s"}`,
    fieldBoundedError: (name, min, max) => `The field ${name} must have at least ${min} character${min === 1 ? "" : "s"} and no more than ${max} characters`,
    existingRosterError: "There is already a roster for this location on this date",
    overtimeError: "There is an employee that exceeds overtime limit",
    noData: "No data found.",

    //General Button Texts
    buttonLoading: "Loading",
    buttonAdd: "Add",
    buttonUpdate: "Update",
    buttonDelete: "Delete",
    buttonCancel: "Cancel",
    buttonCreate: "Create",
    buttonApply: "Apply",
    buttonOkay: "OK",
    buttonPrint: "Print",

    //General Columns
    generalColumnID: "ID",

    //Page Errors
    pageErrorNotAuthorised: "You are not authorised to see this page.",
    pageErrorNotAuthorisedPublic: "Please login from your organization's network.",
    pageErrorUnknown: "An error occurred. Please try again, or contact the support team.",
    pageErrorSessionExpired: {
        title: "Session expired",
        line1: "Your session has expired.",
        line2: "Please login again.",
        button: "Login"
    },

    //User Settings page
    userSettingsTitle: "User Settings",
    userSettingsGeneralTitle: "General",
    userSettingsLanguageDropdown: "Language",
    userSettingsLanguageError: "Error - Could not update.",

    //Restaurants page
    restaurantsPageTitle: "Restaurants",
    restaurantsNameHeader: "Name",
    restaurantsSupervisorHeader: "SV",
    restaurantsAreaManagerHeader: "AMG",
    restaurantsRegionManagerHeader: "RMG",
    restaurantsStoreCharacteristicsUploadTitle: "Import store characteristics",

    //Add Restaurant Dialog
    addRestaurantDialogHeader: "Add Restaurant",
    addRestaurantDialogInstructions: "Please fill in the name of the new restaurant.",
    updateRestaurantDialogHeader: "Update Restaurant",
    updateRestaurantDialogInstructions:"Please enter the updated details for this restaurant.",
    addRestaurantNoNameError: "Please enter a valid name.",
    addRestaurantNameTooShortError: "Restaurant names must have at least 1 character",
    addRestaurantNameTooLongError: "Restaurant names can have no more than 100 characters",
    addRestaurantNameFieldLabel: "Name",
    addRestaurantStoreNumber: "Store Number",
    addRestaurantInvalidStoreNumber: "Store Number must be a number",
    addRestaurantInvalidNumberError:`A location with this store number already exists`,
    addRestaurantInvalidNameError: `A location with this name already exists`,


    //Stations page
    stationsPageTitle: "Stations",
    stationsNameHeader: "Name",
    stationDeleteInUseError: (station) =>  `You cannot delete ${station} because there are still templates or rosters associated with it`,

    //Add Station Dialog
    addStationDialogHeader: "Create New Station",
    addStationDialogInstructions: "Define a new work station for restaurants.",
    updateStationDialogHeader: "Update Station",
    updateStationDialogInstructions:"Please enter the updated details for this station.",
    addStationNameFieldLabel: "Station Name",
    addStationHelpText: "Enter a unique station name",
    addStationExcludeHoursField: "Exclude Station from Roster Summary:",

    //Tasks page
    tasksPageTitle: "Tasks",
    tasksNameHeader: "Name",
    tasksStationHeader: "Station",
    taskDeleteInUseError: (task) =>  `You cannot delete ${task} because there are still templates or rosters associated with it`,

    //Add Task Dialog
    addTaskDialogHeader: "Create New Task",
    addTaskDialogInstructions: "Define a new task for employees.",
    updateTaskDialogHeader: "Update Task",
    updateTaskDialogInstructions:"Please enter the updated details for this task.",
    addTaskNameFieldLabel: "Task Name",
    addTaskHelpText: "Enter a unique task name",
    addTaskStationField: "Station",
    addTaskStationGetStationsError: "Could not get the list of stations",

    //Add User Dialog
    addUserDialogHeader: "Add User",
    addUserDialogInstructions: "Please fill in the name of the new user.",
    updateUserDialogHeader: "Update User",
    updateUserDialogInstructions:"Please enter the updated details for this user.",
    addUserNoNameError: "Please enter a valid email.",
    addUserInvalidHalfwidthKatakana: "Please use fullwidth Katakana instead of halfwidth Katakana",
    addUserInvalidFirstName: "Please enter a valid first name",
    addUserInvalidLastName: "Please enter a valid last name",
    addUserFirstNameTooShort: "First names must be at least 1 character long",
    addUserLastNameTooShort: "Last names must be at least 1 character long",
    addUserFirstNameTooLong: "First Names can have no more than 20 characters",
    addUserLastNameTooLong: "Last Names can have no more than 20 characters",
    addUserInvalidKanjiFirstName: "Please enter a valid Kanji first name",
    addUserInvalidKanjiLastName: "Please enter a valid Kanji last name",
    addUserKanjiFirstNameTooShort: "Kanji first names must be at least 1 character long",
    addUserKanjiLastNameTooShort: "Kanji last names must be at least 1 character long",
    addUserKanjiFirstNameTooLong: "Kanji first Names can have no more than 20 characters",
    addUserKanjiLastNameTooLong: "Kanji last Names can have no more than 20 characters",
    addUsernameFieldLabel: "Email",
    addUserInvalidRole: "Please select a valid role",
    addUserInvalidType: "Please select a valid type.",
    addUserTypeGqlError: "Could not get the list of types.",
    addUserRestaurantTitle: "Please select which restaurants the user can access.",
    addUserMissingRestaurantsMessage: "Could not get the list of restaurants. Please try again.",
    addUserEmployeeIDError: "Employee ID must be 7 characters.",
    addUserLateWorkerCheckbox: "Late Worker",
    addUserForeignStudentCheckbox: "Foreign Student",
    addUserFullTimeContract: "Full Time",
    addUserPartTimeContract: "Part Time",
    addUserRegularContract: "Regular",
    addUserSchoolStudentContract: "High School Student",
    addUserCasualContract: "Casual",
    addUserForeignWorkerContract: "Foreign Worker",
    addUserForeignStudentContract: "Foreign Student",
    addUserAnyOption: "Any",
    addUserStationInvalidOption: "Please select a valid station.",
    addUserLeaderTypeTitle: "Leader Type",
    addUserStationTitle: "Station",
    addUserIgnoreHours: "Ignore Hours",
    addUserLeaderTypeNone: "None",
    addUserLeaderTypeSection: "Section Leader",
    addUserLeaderTypeFkaSL: "Deprecated(fka SL)",
    addUserLeaderTypeTeam: "Team Leader",
    addUserLeaderTypeTeamCandidate: "Team Leader Candidate",
    addUserHourlyRate: "Hourly Rate",
    addUserHolidayHourlyRate: "Weekend/Holiday Rate",
    addUserNoHourlyRateError: "You must set an hourly rate.",
    addUserNoHolidayHourlyRateError: "You must set an hourly rate for holidays.",
    uploadUserInfo: "Upload employee information",
    importUserInfo: "Import employee information",
    uploadManagerInfo: "Upload manager information",
    importManagerInfo: "Import manager information",
    downloadShifts: "Download",
    downloadShiftsDialogTitle: "Download Shifts",
    downloadShiftsDialogDescription: "Please set start and end dates, then click the OK button",
    submit: "Submit",
    noAccountsFileSelected: "No accounts file selected",
    noLocationsFileSelected: "No locations file selected",
    addUserBaseSalaryError: "You must set a base salary.",
    addUserBaseSalaryLabel: "Base Salary",
    addUserGlobalPremiumError: "You must set a global premium.",
    addUserGlobalPremiumLabel: "Global Premium",
    addUserManagerPremiumError: "You must set a manager premium.",
    addUserManagerPremiumLabel: "Manager Premium",
    adduserNavNext: "Next",
    adduserNavPrevious: "Previous",
    adduserNavPersonal: "Personal",
    adduserNavContract: "Contract",
    adduserNavLocation: "Location",
    addUserNonRosterable: "Rosterable",

    editUserData: "Edit user details",
    editUserDataDisableUser: "Exclude this employee from rostering",
    editUserDataSubmit: "Update",

    //Add Dialog
    addNoNameError: "Please enter a valid name.",
    addNameTooShortError: "Names must have at least 1 character",
    addNameTooLongError: "Names can have no more than 100 characters",

    //Confirm Delete
    deleteMessagePlural: (numSelected) => `Are you sure you want to delete ${numSelected} items?`,
    deleteMessageSingular: (itemToDelete) => `Are you sure you want to delete ${itemToDelete}?`,
    deleteHeader: "Delete",
    deleteCancel: "Cancel",
    deleteConfirm: "Confirm",

    //Material Table
    lableRowsPerPage: "Rows per page",
    nextButtonText: "Next Page",
    backButtonText: "Previous Page",
    labelRowsPerPage: 'Rows per page:',
    labelDisplayedRows:({ from, to, count }) => `${from}-${to} of ${count}`,

    //Material Table Toolbar
    materialTableToolbarDelete: "Delete",
    materialTableToolbarAdd: "Add",
    materialTableToolbarSelected: (numSelected) => `${numSelected} selected`,
    materialTableSearchPlaceholder: "Search",

    //Material Table Footer
    materialFooterCancelText: "Cancel",

    //Users Page
    usersUsernameHeader: "Email",
    usersFirstNameHeader: "First Name",
    usersLastNameHeader: "Last Name",
    usersKanjiFirstNameHeader: "Kanji First Name",
    usersKanjiLastNameHeader: "Kanji Last Name",
    usersRoleHeader: "Role",
    userTypeHeader: "Type",
    usersRestaurantHeader: "Restaurant",
    usersRestaurantIDHeader: "ID",
    usersAdminRole: "Admin",
    usersSeniorManagerRole: "Senior Manager",
    usersHQManagerRole: "HQ Manager",
    usersRegionManagerRole: "Region Manager",
    usersAreaManagerRole: "Area Manager",
    usersSupervisorRole: "Supervisor",
    usersRestaurantManagerRole: "Restaurant Manager",
    usersManagerRole: "Manager",
    usersStaffRole: "Staff",
    usersEmployeeIDHeader: "Employee ID",
    userEmploymentTypePartTime: "Part Time",
    userEmploymentTypeRegular: "Regular",
    userEmploymentTypeFullTime: "Full Time",
    userEmploymentTypeHighSchoolStudent: "High School Student",
    userEmploymentTypeStudent: "Student",
    userEmploymentTypeForeignWorker: "Foreign Worker",
    userEmploymentTypeForeignStudent: "Foreign Student",

    //Material Table Row
    materialTableRowViewOption: "View",
    materialTableRowEditOption: "Edit",
    materialTableRowDeleteOption: "Delete",

    //System Settings Page
    sysSettingsStationTitle: "Stations",
    sysSettingsStationDescription: "Setup the work stations for all locations in your organisation.\n Add or update templates and schedules to include new or ammended work stations.",
    sysSettingsTasksTitle: "Tasks",
    sysSettingsTasksDescription: "Setup the tasks that are required by your organisation.\n Add or update stations in templates and schedules to include tasks for your staff.",
    sysSettingsOperatingHrsTitle: "Operating Hours",
    sysSettingsOperatingHrsDescription: "Setup default operating hours and time block size.\n This will set the default rostering hours and time block size for new templates.",
    sysSettingsConfigureButton: "Configure",
    sysSettingsWorkingLimitsTitle: "Working Limits",
    sysSettingsWorkingLimitsDescription: "Set up the requirements on working hours.\n Define values to limit employee work availability.",
    sysSettingsWorkBreakRulesTitle: "Work Break Rules",
    sysSettingsWorkBreakRulesDescription: "Setup the requirements on working break times.\n Define the rules for assigning break times to staff during their working day.",

    //Restaurant Settings
    restaurantSettingsSetHoursInstructions: "Set the operating hours for this restaurant.",
    
    //Opening Hours Configurment Card
    openingHoursConfigCardTitle: "Set Operating Hours",
    openingHoursTimePickerStart: "Start",
    openingHoursTimePickerOpening: "Opening",
    openingHoursTimePickerClosing: "Closing",
    openingHoursTimePickerEnd: "End",
    openingHoursCancelButton: "Cancel",
    openingHoursUpdateButton: "Update",
    openingHoursInvalidTimeChange: "Invalid Time Selected",
    openingHoursInvalidTimeSubmission: "At least one time is invalid",
    openingHoursInvalidStartTimeChange: "Start time must be before opening time",
    openingHoursInvalidOpenTimeChange: "Opening time must be between start time and closing time",
    openingHoursInvalidCloseTimeChange: "Closing time must be between opening time and end time",
    openingHoursInvalidEndTimeChange: "End time must be after closing time",

    operatingDaysOfWeek: [
        'Sundays',
        'Mondays',
        'Tuesdays',
        'Wednesdays',
        'Thursdays',
        'Fridays',
        'Saturdays',
        'Holidays'
    ],

    operatingDaysOfWeekMondayStart: [
        'Mondays',
        'Tuesdays',
        'Wednesdays',
        'Thursdays',
        'Fridays',
        'Saturdays',
        'Sundays',
        'Holidays'
    ],

    //Gantt Sections
    ganttSectionTasks: "TASKS",
    ganttSectionStaff: "STAFF",

    //Templates
    templatesPageTitle: "Templates",
    templatesBatchOptimisation: "Batch Optimisation",
    templatesNameHeader: "Name",
    templatesRestaurantHeader: "Restaurant",
    templateUploadFile: "Upload Template File",
    templateImportButton: "Import From xlsx",
    templateUnableToDeleteUsed: "Unable to Delete Template once it has been used to create Roster(s).",

    //Add Template Dialog
    addTemplateDialogHeader: "Create New Template",
    addTemplateDialogInstructions: "Define a new schedule template.",
    updateTemplateDialogHeader: "Update Template Details",
    updateTemplateDialogInstructions:"Please enter the updated details for this template.",
    addTemplateNameFieldLabel: "Template Name",
    addTemplateHelpText: "Enter a unique template name",
    addTemplateGetRestaurantsError: "Could not get the list of restaurants",
    addTemplateNoRestaurantsError: "You must be associated with a restaurant to create or edit a template",
    addTemplateSalesTargetFieldLabel: "Sales Target",
    addTemplateWeekdayFieldLabel: "Weekdays",
    addTemplateWeekendFieldLabel: "Weekends and Holidays",
    addTemplateBaseHoursFieldLabel: "Base Hours",
    addTemplateBaseHoursFieldInstructions: "Please set the base hours excluding fixed/proportional/plates hours.",
    addTemplateRestaurantFieldLabel: "Restaurant",
    addTemplateSalesTargetDuplicatedError: "Found a template with the same sales target.",

    //Rostering Edit Page
    rosteringEditAddNewTask: "Select Tasks",
    rosteringEditSelectStationsOption: "Select Stations",
    rosteringEditSelectStationsTitle: "Select Stations",
    rosteringEditSelectStationsDescription: "Please select which stations should be available.",
    rosteringEditMissingStationsMessage: "Could not get the list of stations. Please try again.",
    rosteringEditSelectTasksTitle: "Select Tasks",
    rosteringEditSelectTasksDescription: "Please select which tasks should be available.",
    rosteringEditMissingTasksMessage: "Could not get the list of tasks. Please try again.",
    rosteringEditAutoFill: "Auto-fill",
    rosteringEditInsufficientStaff: "No Availability",
    rosteringEditPositionsText: (positionList) => positionList.length === 1 ? `${positionList.length} position` : `${positionList.length} positions`,
    rosteringEditStartTimeAfterEndTimeMessage: "The end time must be after the start time",
    rosteringEditStationHasTasksError: (stationName) => `Unable to remove ${stationName} because it has tasks or positions associated with it.`,
    rosteringStateDraft: "Draft",
    rosteringStatePublished: "Published",
    rosteringStateEditing: "Editing",
    rosteringEditSelectTemplate: "Select a template",
    rosteringEditStoreTarget: "Store Target",
    rosteringEditHQTarget: "HQ Target",
    rosteringEditTenThousandYen: "万円",
    rosteringEditAssignSL: "Assign Section Leader",
    rosteringEditUnassignSL: "Unassign Section Leader",
    rosteringEditDeletePosition: "Delete Position",
    rosteringEditMultipleSLError: "You have already assigned a section leader to this section.",
    rosteringEditTaskPopupTitle: "Employee Tasks",
    rosteringEditTaskPopupNoTasks: "There are no tasks for this employee.",
    rosteringEditPlatesDropdownTitle: "Plates",
    rosteringEditNavigateToPreviousRoster: "Previous Day",
    rosteringEditNavigateToNextRoster: "Next Day",

    //Print Rosters Dialog
    printRostersDialogTitle: "Print Rosters",
    printRostersDialogInstructions: "Select the date range to print rosters.",

    //Add Roster Dialog
    addRosterGetTemplatesError: "Could not get the list of templates",
    addRosterNoTemplatesError: "There are no templates available for this restaurant.",
    addRosterSelectorLabel: "Please Select A Template",
    addRosterTitle: (dateToDisplay) => `Create Roster for ${dateToDisplay}`,

    //Roster Error Report
    errorReportTitle: "Roster Issues",
    errorReportGeneralErrors: "There are some problems with this roster - click here to see details",
    errorReportExcessivePositions: "There are more positions than staff members",
    errorReportNotPublished: "The roster is not currently published",
    errorReportBeforeStart: (stationName, startTime, earliestTime) => `${stationName} has a position starting at ${startTime}. The earliest start time is ${earliestTime}.`,
    errorReportAfterEnd: (stationName, endTime, latestTime) => `${stationName} has a position ending at ${endTime}. The latest end time is ${latestTime}.`,
    errorReportNoPositions: (stationName) => `${stationName} has no positions.`,
    errorReportNoTasks: (stationName) => `${stationName} has no tasks.`,
    errorReportNoTaskSlots: (stationName, taskName) => `The ${taskName} task on ${stationName} has no slots.`,
    errorReportNotEnoughBreak: (
        employeeName,
        breakMinutes,
        minBreakMinutes
    ) => `There is a shift requiring more than ${minBreakMinutes} minutes of break time for ${employeeName}, but only ${breakMinutes} minutes allocated.`,
    errorReportNoAppropriateStaff: (taskName,stationName,startTime,endTime) => `The ${taskName} task in the ${stationName} station (${startTime}-${endTime}) is not currently covered.`,
    errorReportEmployeeNoAvailability: (employeeName) => `${employeeName} is not available on on this day`,
    errorReportEmployeeNotAvailable: (employeeName, start, end) => `${employeeName} is only available ${start} - ${end}`,
    errorReportInsufficentOvertime: (employeeName, start, end) => `${employeeName} does not have sufficient overtime to work a ${start} - ${end} shift`,
    errorReportEmployeeConsecutiveDayWarning: (employeeName) => `${employeeName} has worked for more than 5 consecutive days`,
    errorReportShiftTooLong: (employeeName, maxHoursPerDay) => `${employeeName} cannot work longer than ${maxHoursPerDay} a day.`,
    errorReportShiftTooLate: (employeeName, latestEndTime) => `${employeeName} cannot work after ${latestEndTime}.`,
    errorReportHoursPerWeekWarning: (employeeName, start, end, limit) => `${employeeName} exceeds the weekly work limit (${limit} hours) to work a ${start} - ${end} shift`,
    errorReportHoursDay: (employeeName, start, end, limit) => `${employeeName} exceeds the daily work limit (${limit} hours) to work a ${start} - ${end} shift`,

    //Edit Template Page     
    editTemplateEditOption: "Edit Template Details",

    //calendar:
    calendarToggleView: "Toggle View",
    caldendarMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
    fullDays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ],
    shorthandDates: [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thur',
        'Fri',
        'Sat'
    ],
    calendarCustomMessages:{
        "today":"Today",
        "previous":"Back",
        "next":"Next",
        "month":"Month",
        "week":"Week",
        "day":"Day",
        "agenda":"Agenda"
      },
    dateTitle: (date,day,month) => {return `${day}, ${month} ${appendOrdinal(date)}`},
    dateTitleShort: (date,day,month) => {return `${day.substr(0,3)}, ${month} ${appendOrdinal(date)}`},
    dateFormat: (date,day,month,year) => {return `${day} ${month} ${appendOrdinal(date)} ${year}`},
    dateFormat2: (date,day,month,year) => {return `${day} ${appendOrdinal(date)} ${month} ${year}`},
    dateFormat3: (date,month,year) => {return `${appendOrdinal(date)} ${month} ${year}`},
    dateFormat4: (day,date,month,year) => {return `${day} ${date}/${month}/${year}`},
    dateFormat5: (day,date,month,year) => {return `${month} ${appendOrdinal(date)} ${year}`},
    dateFormat6: (startYear, startMonth, startDay, endYear, endMonth, endDay) => {return `${startMonth} ${appendOrdinal(startDay)} ${startYear} to ${endMonth} ${appendOrdinal(endDay)} ${endYear}`},
    dateFormat7: (day,date,month) => {return `${day} ${date}/${month}`},
    dateFormat8: (startYear, startMonth, startDay) => {return `${startMonth} ${appendOrdinal(startDay)} ${startYear} onwards`},
    dateFormat9: (day, date) => {return `${day} ${appendOrdinal(date)}`},
    dateFormat10: (year, month, day) => {return `${month} ${appendOrdinal(day)} ${year}`},
    dateFormat11: (date,month) => {return `${date}/${month}`},
    timestamp: (date) => {return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`},

    //Staff Page
    staffSkillsTab: "Skills",
    staffSkillsMappingTab: "Skill Mapping",
    staffAvailabilityTab: "Availability",
    staffSettingsTab: "Settings",
    staffSettingsContractTypeHeader: "Contract Type",
    staffAvailabilityPatternTab: "Pattern",
    staffAvailabilityBusySeasonTab: "Busy Season",
    staffAvailabilityModificationTab: "Additional Days / Time Off",
    staffTimeOffTab: "Time Off",
    staffAvailabilityObon: "Obon",
    staffAvailabilityNewYear: "New Year's holiday",

    //Reset Password
    resetPassword: "Reset Password",
    resetPasswordConfirm: "Are you sure you would like to reset this password?",

    //Availability
    staffAvailability: "Availability",
    staffAvailabilityDay: "Day",
    staffAvailabilityStart: "Start",
    staffAvailabilityEnd: "End",
    staffAvailabilityTotalHours: "Total Hours",
    staffAvailabilityTableHeader: "Availability",
    staffAvailabilityStartTimeLabel: "Start Time",
    staffAvailabilityEndTimeLabel: "End Time",
    staffAvailabilityDateLabel: "Date",
    staffAvailabilityAddAvailabilityCard: "Add Availability",
    staffAvailabilityEditAvailabilityCard: "Edit Availability",
    staffAvailabilityTimesError: "Start time must be before end time",
    staffAvailabilityInvalidDate: "Date is invalid",
    staffAvailabilityInvalidStart: "Start time is invalid",
    staffAvailabilityInvalidEnd: "End time is invalid",
    staffAvailabilityOverlapError: "Please organise availabilities as to avoid overlaps",
    staffAvailabilityPatternInstructions: "Set your preferred availability by work day.",
    staffAvailabilityModificationInstructions: "Add a modification to your preferred pattern.",
    staffAvailabilityStartTimeError: (time) => `You may not enter an availability earlier than ${time}`,
    staffAvailabilityEndTimeError: (time) => `You may not enter an availability later than ${time}`,
    staffAvailabilityStartEndValidation: "The start time must always be before the end time.",
    staffAvailabilityApproved: "Approved",
    staffAvailabilityApplied: "Applied",
    staffAvailabilityRejected: "Rejected",
    staffAvailabilityPending: "Pending Approval",
    staffAvailabilityAlreadyApplied: "Availabilities for this period have already been applied, and cannot be changed.",
    staffAvailabilityApplyButton: "Apply",
    staffAvailabilityAppliedNotification: "Your working pattern has been requested.",
    staffAvailabilityErrorNotification: "Your working pattern failed to request.",
    staffAvailabilityMaxDaysPerWeek: "Available Days",

    //Availability Modification
    availabilityModificationSetUnavail: "Set Unavailable",
    availabilityModificationSetAvailTimes: "Set available times",
    availabilityModificationAddTitle: "Add Availability Modification",
    availabilityModificationAddAdditionalDaysAndTimeOffsMessage: "Request additional days or time-offs. Once the requests are approved and applied for rostering, they will be unmodifiable.",
    availabilityModificationAddTimeOffsMessage: "Request time-offs. Once the requests are approved and applied for rostering, they will be unmodifiable.",
    availabilityModificationNotAvailable: "Not Available",
    availabilityModificationsNoModifications: "There are currently no availability modifications.",
    availabilityModificationsStartTimeError: (time) => `You may not enter a modification earlier than ${time}`,
    availabilityModificationsEndTimeError: (time) => `You may not enter a modification later than ${time}`,
    availabilityModificationsStartEndValidation: "The start time must always be before the end time.",
    availabilityModificationsNoPatternToCancel: "No cancellation is needed as the day is already unavailable, or your availability request hasn't been approved.",
    availabilityModificationsAlreadyRequested: "The same request has already been submitted",
    availabilityModificationsDeadlinesTitle: "Deadlines",

    //Availability Time Off
    availabilityTimeOffAddButtonLabel: "Add day off",

    //Availability Review
    availabilityReviewPatternHeader: "Availability Pattern",
    availabilityReviewExceptionHeader: "Availability Exception",
    availabilityReviewPrint: "Print",
    availabilityReviewEditInstructions: "Please update the availability pattern.",
    availabilityReviewExceptionEditInstructions: "Please update the available timeslot.",
    availabilityReviewExceptionEditInstructions2: "The request hasn't been approved. Please set the available timeslot",

    //Staff Skills
    skillsNameHeader: "Name",
    skillsRatingHeader: "Rating",
    staffSkillsDialogAdd: "Add staff skill",
    
    //Station Selector
    stationSelectorAllStations: "All Stations",

    //Staff Overtime
    staffOvertimeCardHeader: "Monthly Overtime",
    staffOvertimeTabHeader: "Overtime",
    staffOvertimeImportButton: "Import",
    staffOvertimeImportHeader: "Upload employee overtime data",

    //Staff school holiday
    staffSchoolHolidayCardTitle: "School Holidays",
    staffSchoolHolidayModalInstructions: "Please set the date range of the holiday。",
    staffSchoolHolidayModalTitle: "School Holiday",
    staffSchoolHolidayStart: "Start",
    staffSchoolHolidayEnd: "End",
    staffSchoolHolidayPeriod: (start, end) => `the holiday(${start} - ${end})`,

    //Batch Optimisation
    batchOptimiseAddButton: "Optimise",
    batchOptimiseInstructions: "To add a background job that creates optimised rosters, select the restaurants and the date range to include in the job.",
    batchOptimiseFrom: "From:",
    batchOptimiseTo: "To:",
    batchJobStartedSuccessfully: "Batch job started successfully",

    //Checkbox
    selectAll: "Select all",
    selectAllResults: "Select all search results",

    //Schedule
    scheduleNoPositionsError: "You must be associated with at least one restaurant to view your schedule.",
    scheduleIconHoverAdd: "Add schedule",
    scheduleIconHoverEdit: "Edit schedule",

    //Shift Time Picker
    shiftTimeLabelStart: "Start",
    shiftTimeLabelEnd: "End",

    //Working Limits
    workingLimitsTitle: "Working Limits",
    workingLimitsCategories: "Categories",
    workingLimitsFullTime: "Full Time",
    workingLimitsPartTime: "Part Time",
    workingLimitsRegular: "Regular",
    workingLimitsHighschoolStudent: "High School Student",
    workingLimitsCasual: "Casual",
    workingLimitsForeignWorker: (highSchooler) => highSchooler ? "Foreign Worker(High School Student)": "Foreign Worker",
    workingLimitsForeignStudent: (highSchooler) => highSchooler ? "Foreign Student(High School Student)" : "Foreign Student",
    workingLimitsDay: "Day",
    workingLimitsWeek: "Week",
    workingLimitsOvertime: "Overtime",
    workingLimitsCloseOfShop: "Close",
    workingLimitsCloseOfBusiness: "Close of Business",
    workingLimitsLateWorkerModification: "Late Worker Modification",
    workingLimitsTermTimeModificiation: "Holiday Time Modification",
    workingLimitsHoursPerDay: (hours) => `Workable hours per day: ${hours}`,
    workingLimitsMaxHoursPerDay: (hours) => `Maximum Hours per day: ${hours}`,
    workingLimitsWorkHours: (range) => `Work hours limit: ${range}`,
    workingLimitsHoursPerWeek: (hours) => `Workable hours per week: ${hours}`,
    workingLimitsDaysPerWeek: (days) => `Maximum days workable per week: ${days}`,
    workingLimitsContinuousDays: (days) => `Maximum continuous days workable: ${days}`,
    workingLimitsOvertimeHoursPerMonth: (hours) => `Overtime hours per month: ${hours}`,

    //Deadlines
    availabilityPeriod: "Period",
    availabilityPeriodAmendmentStart: "Start Date",
    availabilityPeriodAmendmentEnd: "End Date",
    availabilityPeriodModalTitle: "Deadline of availability period",
    availabilityPeriodCardTitle: "Deadlines of availability periods",
    availabilityPeriodTargetPeriod: "Period: ",
    availabilityPeriodNotFound: "The current availability period not defined. You cannot submit availabilities.",
    availabilityPeriodFailedToDelete: (period) => `The availability period is in use, and cannot be removed : ${period}`,
    availabilityExceptionPeriod: "Period",
    availabilityExceptionPeriodDeadline: "Deadline",
    availabilityExceptionPeriodModalTitle: "Deadline of availability modification",
    availabilityExceptionPeriodCardTitle: "Deadlines of availability modifications",
    availabilityExceptionPeriodNotFound: "Availability modification periods not defined. You cannot modify availabilities.",

    //Breaks
    breaksPageTitle: "Breaks",
    breaksLowerHours: "Lower (Hours)",
    breaksUpperHours: "Upper (Hours)",
    breaksDurationMinutes: "Duration (Minutes)",
    breaksBreakRules: "Break Rules",
    breaksMinTimeFromStartString: (number) => `Minimum time from start of shift for break: ${number} ${number === 1 ? "hour" : "hours"}`,
    breaksPreferredSmallestBreakString: (number) => `Preferred smallest break when splitting: ${number} ${number === 1 ? "minute" : "minutes"}`,
    breaksMinTimeFromEndString: (number) => `Minimum time from end of shift for break: ${number} ${number === 1 ? "hour" : "hours"}`,
    breakStartAfterEndError: "The end time cannot be before the start.",
    breakInvalidStartTimeError: "The start time cannot fall outside operating hours.",
    breakInvalidEndTimeError: "The end time cannot fall outside operating hours.",

    //WebSocket
    webSocketConnectionUnknownStatus: "Connection status: Unknown",
    webSocketConnectionStats: "Connection status:",
    webSocketConnectionConnecting: "Connecting",
    webSocketConnectionOpen: "Open",
    webSocketConnectionClosing:"Closing",
    webSocketConnectionClosed:"Closed",
    webSocketConnectionUninstantiated:"Uninstantiated",
    webSocketAutoFill: "Auto-Fill",

    //Upload staff Availability
    availabilityUploadFile: "Upload Availability File",
    availabilitySelectType: "Select file type",
    availabilityRadioPattern: "Availability pattern",
    availabilityRadioExceptions: "Availability exceptions",
    availabilityChooseFile: "Choose File",
    availabilityUploadSuccess: "File uploaded successfully",
    availabilityImportStaffButton: "Import Staff Availability",

    //Download staff Availability
    availabilityExportPreferredAvailability: "Export Preferred Availability",
    availabilityExportAvailabilityModifications: "Export Availability Modifications",
    availabilityPreferredFileName: "staffPreferredAvailability",
    availabilityModificationsFileName: "staffAvailabilityModifications",

    //useContactData
    contactDataLastHourWorker: "Last Hour Worker",
    contactDataForeigner: "Foreigner",
    contactDataHighSchooler: "High School Student",
    contactDataSummerBreak: "Summer Break",
    contactDataStartDate: "Start Date",
    contactDataEndDate: "End Date",
    contactDataTo: "To",

    //Roster optimisation problems
    rosterValidationCriticalNoStations: "No stations available. Add at least 1 station to roster.",
    rosterValidationCriticalNoStaff: "No staff available for rostering.",
    rosterValidationCriticalNoTasks: "No tasks defined in roster. Please add at least 1 task for each station.",
    rosterValidationCriticalNoTimeSlots: "No tasks timeslots defined in roster. Please add at least 1 task timeslot for each station.",
    rosterValidationNoWorkToDo: "Unable to allocate staff. All positions have been filled.",
    rosterValidationInsufficientStaff: "There is insufficient available staff for all positions, roster will not be complete.",

    //Confirmation Prompt Text
    confirmationFileMessage: "Are you sure you want to upload this file? All current data will be overwritten.",
    confirmationCancel: "Cancel",
    confirmationAccept: "Accept",

    //Delete Roster
    deleteRoster: "Delete Roster",
    deleteRosterConfirmation: "Would you like to delete this roster?",

    //Print Roster
    printRoster: "Print Roster",

    //Staff Health Check Roster
    healthCheckOption: "Staff Health Check",

    //Health Check
    healthCheckList: "Health Checklist",
    healthCheckListStoreTemp: "Temp. at Store",
    healthCheckListHomeTemp: "Temp. at Home",
    healthCheckCough: "Cough",
    healthCheckFatigue: "Fatigue",
    healthCheckLossOfTasteOrSmell: "Loss of Taste or Smell",
    healthCheck3rdPartyInfection: "Infection of close friends or co-workers",
    healthCheckAbdominalPain: "Diarrhea / vomiting / abdominal pain",
    healthCheckMobileBan: "Ban on carrying mobile",
    healthCheckInfection: "Infection in Household",
    healthCheckYes: "Yes",
    healthCheckNo: "No",
    healthCheckYesNo: ("Yes/No"),
    healthCheckTemperatureUnit1: "°C",
    healthCheckTemperatureUnit2: "(°C)",

    //Change Roster Template
    changeTemplatePrompt: "Are you sure you want to change this template? All current data will be replaced.",
    changeTemplateReplace: "Replace",
    changeTemplateCancel: "Cancel",

    //Template Header Content
    templateHeaderSalesTarget: "Sales Target",

    //Roster status
    rosterEditing: "Editing",
    rosterPublished: "Published",
    rosterRepublished: "Republished",

    //Publish toggle
    rosterPublish: "Publish",

    //Template Add Roster
    templateThousandYen: "K Yen",
    templateNoTemplateTarget: "(No Target)",
    templateStoreTarget: "Store Target",
    templateSelectByTarget: "Select template by target",
    templateHQTarget: "HQ Target",
    templateNotSet: "Not Set",
    templateTargetSetByHQ: "Target set by HQ",
    templateSingular: "Template",

    //Shifts
    shiftsYourNextShifts: "Your next shifts",
    shiftsNoneAssignedError: "You do not have any shifts assigned.",
    shiftsBreak: (length, time) => `${length} minute break at ${time}`,
    shiftsUnpublished: "UNPUB",

    //Roster Summary
    rosterSummaryAverageWage: "Average wage",
    rosterSummaryDailySalary: "Daily salary",
    rosterSummaryExpenseRatio: "Personnel expense ratio",
    rosterSummaryTotalHours: "Total Hours",
    rosterSummaryBaseHours: "Base Hours",
    rosterSummaryExcess: "Excess",
    rosterSummaryInsufficient: "Insufficient",
    rosterSummaryTotalBreakMinutes: "Total break minutes",
    rosterSummaryPersonnelCount: "Personnel count",
    rosterSummaryExpPersonnelCount: "Expected personnel count",
    rosterSummaryAfterHours: "After Hours",

    //Targets page
    targetsPageTitle: "Targets",
    targetsNoTemplateError: (target) => `No template with target ${target} found.`,
    targetsStoreHeader: "Store",
    targetsStoreNameHeader: "Store Name",
    targetsSettingsHeader: "Settings",
    targetsNotSet: "Not set",
    targetsNA: "N/A",
    targetsHqSales: "HQ Sales",
    targetsStoreSales: "Store Sales",
    targetsProportionalHours: "Proportional",
    targetsFixedHours: "Fixed",
    targetsPlates: "Plates",
    targetsDatePickerLabel: "Select from date",
    targetUploadFileDialogLabel: "Upload revenue targets and extra hours",
    targetUploadCSVButton: "Import",
    targetsNoAssignedRestaurantsError: "You must be assigned to at least one restaurant to view targets.",
    targetsAssigned: "Assigned",
    targetsPreferred: "Preferred",
    targetsDifference: "Difference",
    noSalesTargetsFileSelected: "No sales targets file selected",
    noFixedExtraHoursFileSelected: "No fixed extra hours file selected",
    noProportionalExtraHoursFileSelected: "No proportional extra hours file selected",

    //Slider
    sliderStart: "Start",
    sliderOpen: "Open",
    sliderClose: "Close",
    sliderEnd: "End",

    //Fixed Positions
    fixedPositions: "Fixed Positions",
    editFixedPositionsTitle: "Fixed shift pattern",
    editFixedPositionsInstructions: "Set a pattern of fixed shifts for this staff member.",
    editFixedPositionsHeaderDay: "Day",
    editFixedPositionsHeaderEnable: "Enable",
    editFixedPositionsHeaderStation: "Station",
    editFixedPositionsHeaderTime: "Time",
    editFixedPositionsNoStationsError: "There must be stations configured before fixed positions can be set.",
    editFixedPositionsStartEndError: "The start time must always be before the end time.",
    createFixedPositionHeader: "Add a user to fixed shift patterns",
    createFixedPositionInstructions: "Select the user to add to the list of fixed positions",
    createFixedPositionLocationFieldLabel: "Location",
    createFixedPositionStaffFieldLabel: "Staff",
    editFixedPositionsHeaderTotal: "Total",
    deleteFixedPositionSingular: (name: string) => `Would you like to clear the fixed positions for ${name}?`,
    deleteFixedPositionPlural: (count: number) => `Would you like to clear the data for ${count} fixed positions?`,
    editFixedPositionTabPattern: "Fixed Shift Pattern",
    editFixedPositionTabExceptions: "Fixed Shift Exceptions",
    editFixedPositionExceptionsHeading: "Fixed shift exceptions",
    editFixedPositionExceptionsDescription: "Edit exceptions to the fixed shift pattern for this staff member",
    editFixedPositionExceptionsHeaderDate: "Date",
    editFixedPositionExceptionsHeaderException: "Exception",
    editFixedPositionExceptionsHeaderTime: "Time",
    editFixedPositionExceptionsRemoved: "Remove date from pattern",
    addFixedPositionExceptionsTitle: "Add a fixed shift exception",
    addFixedPositionExceptionsSubtitle: "The fixed shift can be overridden with an exception that either sets a new time span for a particular date, or removes the date entirely.",
    addFixedPositionExceptionsSelectDate: "Select Date",
    addFixedPositionExceptionsRemoveDate: "Remove this date",
    addFixedPositionExceptionsSetStation: "Set station and times for this date",
    addFixedPositionExceptionsInvalidStationList: "The station list could not be acquired.",
    addFixedPositionExceptionsStartTimeAfterEndTimeMessage: "The end time must be after the start time.",
    addFixedPositionExceptionsAlreadyExists: "A fixed position already exists for this day.",
    employeeTimeSlotStartTimeAfterEndTimeMessage: "The start time must be before the end time.",
    employeeTimeSlotEndTimeBeforeStartTimeMessage: "The end time must be after the start time.",
    employeeTimeSlotTooLong: (duration) => {return `You are not allowed to work longer than ${duration} hours`},

    // Import Errors
    importEmployeesEmptyIDErr: "Employee ID cannot be empty.",
    importEmployeesIDLengthErr: "Employee ID must be 7 characters.",
    importEmployeesFirstLastEmptyName: "firstName and lastName cannot be empty.",
    importEmployeesInvalidLeaderVal: "Invalid Leader value.",
    importEmployeesInvalidCourseVal: "Invalid Course value.",
    importEmployeesInvalidEmployeeRole: "Invalid employee role.",
    importSenManagerDuplicateStoreID: "Duplicated store IDs or names are found.",
    importSenManagerEmployeeNotFound: (id, name) => {return `Employee ${id}:${name} not found.`},
    importSenManagerDuplicateEmployeeID: "Duplicated employee IDs/Emails found.",
    importAvailabilityDataMissing: (index) => {return `Row(${index}): data columns are missing.`},
    importAvailabilityMonthDurationErr: (months) => {return `duration ${months} must be between 1 and 6.`},
    importAvailabilityInvalidMonth: (month) => {return `month ${month} is invalid.`},
    importAvailabilityExceptPlanTypeInvalid: (name, id) => {return `New plan type is invalid for ${name}(${id}).`},
    importAvailabilityExceptTimeNotSet: (name, id) => {return `New time is not set for ${name}(${id}).`},
    importTargetsMissingDate: "Dates are missing.",
    importTargetsInvalidDate: (date) => {return `String '${date}' was not recognized as a valid DateTime.`},
    importTargetsLocationError: (locationNumber) => {return `Location number ${locationNumber} is not found or not accessible.`},
    importTargetsTemplateError: (locationName) => {return `Templates for ${locationName} don't exist.`},
    importLocationsDuplicateID: "Duplicated location IDs found.",
    importTemplatesSheetTargetMatchErr: "Sheet name and target value (A2 cell) must match.",
    importTemplatesBaseMasterPatternErr: "Base masters must have task pattern sheets for weekday and weekend.",
    importTemplatesBaseMasterTemplateErr: "Base masters must have templates for a single location.",
    importTemplatesLocationDoesntExist: (id) => {return `Location (${id}) doesn't exist.`},
    importTemplatesBaseMasterLocationIdErr: (id) => {return `You are not allowed to upload base masters for location (${id}).`},
    importTemplatesLocationIDerr: (id) => {return `No location with id ${id}.`},
    importTemplatesTaskDoesntExist: (task) => {return `Task (${task}) doesn't exist in the system.`},
    importTemplatesStationDoesntExist: (station) => {return `Station (${station}) doesn't exist in the system.`},
    importAccountInfoMissing: (employeeIds) => {return `The following Account Information is missing. Please update the account file, and try it again. [${employeeIds}]`},

    //No staff error
    noStaffError: "There are no staff assigned to your location.",

    //Overtime
    overtime: "Overtime",
    monthlyOvertime: "Monthly Overtime",
    monthlyOvertimeNameHeader: "Name",

    //BatchOptimisation
    batchOptimisation: "Optimise",
    batchOptimisationNameHeader: "Name",

    //Rostered Staff Review
    rosteredStaff: "Rostered Staff",
    rosteredStaffPrintTitle: (pageNumber: number, pageTotal: number) => `Rostered Staff - ${pageNumber} of ${pageTotal}`,
    rosteredStaffNameHeader: "Name",
    rosteredStaffCheckColumnHeader: "Check",

    //Daily Duties
    fullTimeEmployeeShiftHeader: "Name",
    stationDutyHeader: "Time Window",
    dailyDuties: "Daily Duties",
    dailyDutySectionHeaderManager: "Manager",
    dailyDutySectionHeaderFullTimeEmployee: "FullTime Employees",
    dailyDutySectionHeaderGeneral: "General",
    dailyDutySectionHeaderBackyard: "Backyard",
    dailyDutySectionHeaderNigiri: "Nigiri",
    dailyDutySectionHeaderGunkan: "Gunkan",
    dailyDutySectionHeaderFloor: "Floor",
    dailyDutySectionHeaderCashier: "Cashiers",
    dailyDutySectionHeaderCleanliness: "Cleanliness",
    timeWindow0914: "9:00-14:00",
    timeWindow1417: "14:00-17:00",
    timeWindow1720: "17:00-20:00",
    timeWindow2023: "20:00-23:00",
    morningSectionLeader: "Morning SL",
    nightSectionLeader: "Night SL",
    dailyDutyPrintTitle: (pageNumber: number, pageTotal: number) => `Daily Duties - ${pageNumber} of ${pageTotal}`,
    dailyDutyNotSelected: "Not Selected",

    //Common
    fileDownloadedSuccessfully: "File downloaded successfully",
    dateRangeFrom: "From:",
    dateRangeTo: "To:",

    //Availability Review
    availabilityReview: "Staff Availability Requests",
    availabilityReviewNameHeader: "Name",
    availabilityReviewMaxDaysPerWeekHeader: "Available Days",
    availabilityReviewRemoved: "Removed",
    availabilityReviewApprove: "Approve",
    availabilityReviewApproved: "Approved",
    availabilityReviewDeny: "Deny",
    availabilityReviewDenied: "Denied",
    availabilityReviewApplied: "Applied",
    availabilityReviewConformation: "Are you sure you want to deny this request?",
    availabilityReviewPrintOff: "OFF",
    availabilityReviewPrintTitle: (pageNumber: number, pageTotal: number) => `Availability - ${pageNumber} of ${pageTotal}`,
    availabilityReviewConfirmButton: "Confirm",
    availabilityReviewApplyButton: "Apply",
    availabilityReviewConfirmApply: "Are you sure you want to apply these changes to staff availability?",
    availabilityReviewUnapprovedExceptionsError: "There are unapproved exceptions for this location",
    availabilityReviewUnapprovedPatternsError: "There are unapproved patterns for this location",

    locationPickerUnassigned: "Unassigned",

    //Training
    training: "Training Progress",
    trainingSetup: "Training Setup",
    trainingName: "Name",
    trainingNoDataError: "There's no training data for this station",
    trainingEditItem: "Edit Training Item",
    trainingAddItem: "Add Training Item",
    trainingUpload: "Upload training",
    trainingDownloadSuccess: "File downloaded successfully",
    trainingImport: "Import Training Sheets",
    trainingExport: "Export Training Sheets",

    //Global Search
    globalSearchPrompt: "Please enter a search term",
    globalSearchToggle: "Global Search",
    searchNoResultsError: (searchTerm) => `There are no results matching '${searchTerm}'.`,

    //Training Category
    trainingCategoryDelete: "Delete",
    trainingCategoryEditItem: "Edit Item",
    trainingCategoryAddCategory: "Add new category",
    trainingCategoryEditCategory: "Edit category",
    trainingCategoryAddItem: "Add training item",
    defaultTrainingItemName: "NewTrainingItem"
};
