export const jaStrings = {
    appTitle: "くら寿司 - 勤務シフト管理",

    //NavBar
    navDrawerCreateSchedule: "ベースシフト",
    navDrawerBatchOptimise: "自動作成",
    navDrawerSettings: "設定",
    navDrawerUserAccount: "言語設定",
    navDrawerSystemSettings: "システム",
    navDrawerRestaurants: "店舗",
    navDrawerRestaurant: "店舗",
    navDrawerUsers: "ユーザー",
    navDrawerStaff: "従業員一覧",
    navDrawerLogout: "ログアウト",
    navDrawerCalendar: "日別シフト",
    navDrawerShifts: "シフト",
    navDrawerAvailability: "出勤可能日",
    navDrawerTraining: "教育",
    navDrawerTrainingSetup: "教育設定",
    navDrawerRosteredStaff: "張出シフト",
    navDrawerDailyDuties: "担当者一覧表",
    navDrawerDeadlineSetup: "締切り設定",

    // Filters
    regionManagerFilterLabel: "ブロック長",
    areaManagerFilterLabel: "AMG",
    supervisorFilterLabel: "SV",
    locationFilterLabel: "店舗",
    stationFilterLabel: "ポジション",
    filterItemOther: "その他",
    filterItemAll: "すべて選択",

    //General GQL Errors
    gqlErrorAuth: "このアクションを実行する権限がありません。",
    gqlErrorUnknown: "エラーが発生しました。再試行するか、サポートチームへ連絡してください。",
    generalUnknownError: "エラーが発生しました。再試行するか、サポートチームへ連絡してください。",
    gqlDuplicateEmailError: "このメールアドレスは既に使用されています。メールアドレスを確認のうえ、再試行してください。",
    gqlDuplicateIdError: (id) => `ID${id}は既に使用されています。IDを確認のうえ、再試行してください。`,
    gqlDuplicateIdGeneralError: `このIDは既に使用されています。IDを確認のうえ、再試行してください。`,
    pageErrorNetworkError: "ネットワークエラーが発生しました。再試行するか、サポートチームへ連絡してください。",
    notAvailableError: "アプリは現在利用できません。再試行するか、サポートチームへ連絡してください。",
    missingTaskOrPositionError: "ポジションには少なくとも1つの業務内容と1つの時間枠が設定されている必要があります",
    existingTemplateError: "同じ名前のベースシフトが存在します",
    breakLengthTooShortError: (employeeName, breakMinutes) => `${employeeName}さんには、${breakMinutes}分以上の休憩が必要なシフトがありますが、十分な休憩時間が割り当てられていません。`,
    fieldExactLengthError: (name, number) => `"${name}"は${number}文字でなければなりません`,
    fieldTooLongError: (name, number) => `"${name}"は最大${number}文字です`,
    fieldTooShortError: (name, number) => `"${name}"は少なくとも${number}文字です`,
    fieldBoundedError: (name, min, max) => `"${name}"は少なくとも${min}文字で、最大${max}文字です`,
    existingRosterError: "この店舗のこの日付のシフトは既に存在します",
    overtimeError: "残業時間の上限を超えた従業員が含まれています。",
    noData: "データがありません。",

    //General Button Texts
    buttonLoading: "ロード中",
    buttonAdd: "追加",
    buttonUpdate: "更新",
    buttonDelete: "削除",
    buttonCancel: "キャンセル",
    buttonCreate: "追加",
    buttonApply: "適用",
    buttonOkay: "OK",
    buttonPrint: "印刷",

    //General Columns
    generalColumnID: "店番",

    //Page Errors
    pageErrorNotAuthorised: "このページを閲覧する権限がありません。",
    pageErrorNotAuthorisedPublic: "社内ネットワークからログインしてください。",
    pageErrorUnknown: "エラーが発生しました。再試行するか、サポートチームへ連絡してください。",
    pageErrorSessionExpired: {
        title: "セッション期限切れ",
        line1: "セッションの有効期限が切れました。",
        line2: "もう一度ログインしてください。",
        button: "再ログイン"
    },

    //User Settings page
    userSettingsTitle: "ユーザー設定",
    userSettingsGeneralTitle: "一般",
    userSettingsLanguageDropdown: "言語",
    userSettingsLanguageError: "エラー：更新できませんでした。",

    //Restaurants page
    restaurantsPageTitle: "店舗",
    restaurantsNameHeader: "名前",
    restaurantsSupervisorHeader: "SV",
    restaurantsAreaManagerHeader: "AMG",
    restaurantsRegionManagerHeader: "ブロック長",
    restaurantsStoreCharacteristicsUploadTitle: "店舗特性のインポート",

    //Add Restaurant Dialog
    addRestaurantDialogHeader: "店舗の追加",
    addRestaurantDialogInstructions: "新しい店舗の名前を入力してください。",
    updateRestaurantDialogHeader: "店舗の更新",
    updateRestaurantDialogInstructions: "この店舗の詳細を更新してください。",
    addRestaurantNoNameError: "名前を正しく入力してください",
    addRestaurantNameTooShortError: "店舗名は1文字以上でなければなりません。",
    addRestaurantNameTooLongError: "店舗名は100文字以内でなければなりません。",
    addRestaurantNameFieldLabel: "名前",
    addRestaurantStoreNumber: "店番",
    addRestaurantInvalidStoreNumber: "店番は数値でなければなりません。",
    addRestaurantInvalidNumberError: `同じ店番を持つ店舗が既に存在します`,
    addRestaurantInvalidNameError: `同じ名前の店舗が存在します`,

    //Stations page
    stationsPageTitle: "ポジション",
    stationsNameHeader: "名前",
    stationDeleteInUseError: (station) =>  `${station}はベースシフトまたはシフトで使用されているため、削除できません`,

    //Add Station Dialog
    addStationDialogHeader: "ポジションの新規追加",
    addStationDialogInstructions: "店舗の新しいポジションを作成します。",
    updateStationDialogHeader: "ポジションの更新",
    updateStationDialogInstructions:"このポジションの詳細を更新してください。",
    addStationNameFieldLabel: "ポジション名",
    addStationHelpText: "重複しないポジション名を入力してください",
    addStationExcludeHoursField: "このポジションを合計時間の対象から除外",

    //Tasks page
    tasksPageTitle: "業務内容",
    tasksNameHeader: "名前",
    tasksStationHeader: "ポジション",
    taskDeleteInUseError: (task) =>  `${task}はベースシフトまたはシフトで使用されているため、削除できません`,

    //Add Task Dialog
    addTaskDialogHeader: "業務内容の新規追加",
    addTaskDialogInstructions: "従業員の新しい業務内容を作成します。",
    updateTaskDialogHeader: "業務内容の更新",
    updateTaskDialogInstructions:"この業務内容の詳細を更新してください。",
    addTaskNameFieldLabel: "業務内容名",
    addTaskHelpText: "重複しない業務内容名を入力してください",
    addTaskStationField: "ポジション",
    addTaskStationGetStationsError: "ポジションのリストを取得できませんでした。",

    //Add User Dialog
    addUserDialogHeader: "ユーザーの追加",
    addUserDialogInstructions: "新しいユーザーの名前を入力してください。",
    updateUserDialogHeader: "ユーザーの更新",
    updateUserDialogInstructions:"このユーザーの詳細を更新してください。",
    addUserNoNameError: "メールアドレスを正しく入力してください。",
    addUserInvalidHalfwidthKatakana: "半角カタカナではなく、全角カタカナを使用してください。",
    addUserInvalidFirstName: "名(カナ)を正しく入力してください。",
    addUserInvalidLastName: "姓(カナ)を正しく入力してください。",
    addUserFirstNameTooShort: "名(カナ)は1文字以上でなければなりません。",
    addUserLastNameTooShort: "姓(カナ)は1文字以上でなければなりません。",
    addUserFirstNameTooLong: "名(カナ)は100文字以内でなければなりません。",
    addUserLastNameTooLong: "姓(カナ)は100文字以内でなければなりません。",
    addUserInvalidKanjiFirstName: "名を正しく入力してください。",
    addUserInvalidKanjiLastName: "姓を正しく入力してください。",
    addUserKanjiFirstNameTooShort: "名は1文字以上でなければなりません",
    addUserKanjiLastNameTooShort: "姓は1文字以上でなければなりません。",
    addUserKanjiFirstNameTooLong: "名は100文字以内でなければなりません。",
    addUserKanjiLastNameTooLong: "姓は100文字以内でなければなりません。",
    addUsernameFieldLabel: "メールアドレス",
    addUserInvalidRole: "役割を正しく選択してください。",
    addUserInvalidType: "契約の種類を正しく選択してください。",
    addUserTypeGqlError: "契約種類のリストを取得できませんでした。",
    addUserRestaurantTitle: "ユーザーがアクセスできる店舗を選択してください。",
    addUserMissingRestaurantsMessage: "店舗のリストを取得できませんでした。再試行してください。",
    addUserEmployeeIDError: "7桁の従業員番号を入力してください。",
    addUserLateWorkerCheckbox: "ラスト契約",
    addUserForeignStudentCheckbox: "留学生",
    addUserFullTimeContract: "フルタイム",
    addUserPartTimeContract: "パート",
    addUserRegularContract: "一般",
    addUserSchoolStudentContract: "高校生",
    addUserCasualContract: "カジュアル",
    addUserForeignWorkerContract: "外国人",
    addUserForeignStudentContract: "外国人留学生",
    addUserAnyOption: "特定しない",
    addUserStationInvalidOption: "ポジションを指定してください。",
    addUserLeaderTypeTitle: "リーダ区分",
    addUserStationTitle: "ポジション",
    addUserIgnoreHours: "シフトの合計時間から除外",
    addUserLeaderTypeNone: "無し",
    addUserLeaderTypeSection: "セクションリーダー",
    addUserLeaderTypeFkaSL: "登録禁止(旧正SL)",
    addUserLeaderTypeTeam: "統括リーダー",
    addUserLeaderTypeTeamCandidate: "統括リーダー(一次試験合格)",
    addUserHourlyRate: "時給平日",
    addUserHolidayHourlyRate: "時給休日",
    addUserNoHourlyRateError: "時給平日を入力してください。",
    addUserNoHolidayHourlyRateError: "時給休日を入力してください。",
    uploadUserInfo: "人事データのアップロード",
    importUserInfo: "人事データのインポート",
    uploadManagerInfo: "エリアデータのアップロード",
    importManagerInfo: "エリアデータのインポート",
    downloadShifts: "ダウンロード",
    downloadShiftsDialogTitle: "シフトのダウンロード",
    downloadShiftsDialogDescription: "対象期間を選択し、OKボタンを押してください。",
    submit: "送信",
    noAccountsFileSelected: "シフト管理システムアカウント一覧が選択されていません",
    noLocationsFileSelected: "エリアデータが選択されていません",
    addUserBaseSalaryError: "基本給を入力してください。",
    addUserBaseSalaryLabel: "基本給",
    addUserGlobalPremiumError: "グローバル給を入力してください。",
    addUserGlobalPremiumLabel: "グローバル給",
    addUserManagerPremiumError: "役職手当を入力してください。",
    addUserManagerPremiumLabel: "役職手当",
    adduserNavNext: "次へ",
    adduserNavPrevious: "前へ",
    adduserNavPersonal: "個人情報",
    adduserNavContract: "役割",
    adduserNavLocation: "店舗",
    addUserNonRosterable: "シフトの対象",

    editUserData: "従業員情報の編集",
    editUserDataDisableUser: "この従業員をシフトの対象から外す",
    editUserDataSubmit: "更新",

    //Add Dialog
    addNoNameError: "名前を正しく入力してください。",
    addNameTooShortError: "名前は1文字以上でなければなりません。",
    addNameTooLongError: "名前は100文字以内でなければなりません。",

    //Confirm Delete
    deleteMessagePlural: (numSelected) => `${numSelected} 件を削除しますが、よろしいですか？`,
    deleteMessageSingular: (itemToDelete) => `${itemToDelete} を削除しますが、よろしいですか？`,
    deleteHeader: "削除",
    deleteCancel: "キャンセル",
    deleteConfirm: "確認",

    //Material Table
    lableRowsPerPage: "ページあたりの件数",
    nextButtonText: "次のページ",
    backButtonText: "前のページ",
    labelRowsPerPage: 'ページあたりの件数：',
    labelDisplayedRows:({ from, to, count }) => `${count}件中 ${from}-${to}`,

    //Material Table Toolbar
    materialTableToolbarDelete: "削除",
    materialTableToolbarAdd: "追加",
    materialTableToolbarSelected: (numSelected) => `選択された数：${numSelected}`,
    materialTableSearchPlaceholder: "検索",

    //Material Table Footer
    materialFooterCancelText: "キャンセル",

    //Users Page
    usersUsernameHeader: "メールアドレス",
    usersFirstNameHeader: "名(カナ)",
    usersLastNameHeader: "姓(カナ)",
    usersKanjiFirstNameHeader: "名",
    usersKanjiLastNameHeader: "姓",
    usersRoleHeader: "役割",
    userTypeHeader: "契約の種類",
    usersRestaurantHeader: "店舗",
    usersRestaurantIDHeader: "店番",
    usersAdminRole: "管理者",
    usersSeniorManagerRole: "シニアマネージャー",
    usersHQManagerRole: "本部",
    usersRegionManagerRole: "ブロック長",
    usersAreaManagerRole: "AMG",
    usersSupervisorRole: "SV",
    usersRestaurantManagerRole: "店長",
    usersManagerRole: "マネージャー",
    usersStaffRole: "スタッフ",
    usersEmployeeIDHeader: "従業員番号",
    userEmploymentTypePartTime: "パート",
    userEmploymentTypeRegular: "一般",
    userEmploymentTypeFullTime: "フルタイム",
    userEmploymentTypeHighSchoolStudent: "高校生",
    userEmploymentTypeStudent: "学生",
    userEmploymentTypeForeignWorker: "外国人",
    userEmploymentTypeForeignStudent: "外国人留学生",

    //Material Table Row
    materialTableRowViewOption: "表示",
    materialTableRowEditOption: "編集",
    materialTableRowDeleteOption: "削除",

    //System Settings Page
    sysSettingsStationTitle: "ポジション",
    sysSettingsStationDescription: "全店舗で共通のポジションを定義します。新規または修正後のポジションはベースシフトと勤務シフトに反映されます。",
    sysSettingsTasksTitle: "業務内容",
    sysSettingsTasksDescription: "全店舗で共通の業務内容を定義します。業務内容は、ベースシフトと勤務シフトのポジションに反映されます。",
    sysSettingsOperatingHrsTitle: "営業時間",
    sysSettingsOperatingHrsDescription: "標準営業時間を設定します。これは店舗ごとの営業時間の初期値となり、ベースシフト、シフト作成時に使用されます。",
    sysSettingsConfigureButton: "設定",
    sysSettingsWorkingLimitsTitle: "勤務制限",
    sysSettingsWorkingLimitsDescription: "勤務時間のルールを設定します。\nここでは従業員の勤務可能時間や日数などを定義します。",
    sysSettingsWorkBreakRulesTitle: "休憩時間のルール",
    sysSettingsWorkBreakRulesDescription: "休憩時間のルールを設定します。\n ここでは勤務時間内に従業員に休憩を与えるルールを定義します。",

    //Restaurant Settings
    restaurantSettingsSetHoursInstructions: "店舗の営業時間を設定してください。",

    //Opening Hours Configurment Card
    openingHoursConfigCardTitle: "営業時間の設定",
    openingHoursTimePickerStart: "開始",
    openingHoursTimePickerOpening: "開店",
    openingHoursTimePickerClosing: "閉店",
    openingHoursTimePickerEnd: "終了",
    openingHoursCancelButton: "キャンセル",
    openingHoursUpdateButton: "更新",
    openingHoursInvalidTimeChange: "指定された時間に問題があります",
    openingHoursInvalidTimeSubmission: "指定された時間に問題があります",
    openingHoursInvalidStartTimeChange: "開始時間は開店時間より前でなければなりません",
    openingHoursInvalidOpenTimeChange: "開店時間は開始時間と閉店時間の間でなければなりません",
    openingHoursInvalidCloseTimeChange: "閉店時間は開店時間と終了時間の間でなければなりません",
    openingHoursInvalidEndTimeChange: "終了時間は閉店時間より後でなければなりません",

    operatingDaysOfWeek: [
        '日曜',
        '月曜',
        '火曜',
        '水曜',
        '木曜',
        '金曜',
        '土曜',
        '祝日'
    ],

    operatingDaysOfWeekMondayStart: [
        '月曜',
        '火曜',
        '水曜',
        '木曜',
        '金曜',
        '土曜',
        '日曜',
        '祝日'
    ],

    //Gantt Sections
    ganttSectionTasks: "業務内容",
    ganttSectionStaff: "スタッフ",

    //Templates
    templatesPageTitle: "ベースシフト",
    templatesNameHeader: "名前",
    templatesRestaurantHeader: "店舗",
    templateUploadFile: "ベースマスターのアップロード",
    templateImportButton: "ベースマスターのインポート",
    templatesBatchOptimisation: "シフトの自動作成",
    templateUnableToDeleteUsed: "シフト作成に使用されたベースシフトは削除できません。",

    //Add Template Dialog
    addTemplateDialogHeader: "ベースシフトの追加",
    addTemplateDialogInstructions: "新しいベースシフトを作成します。",
    updateTemplateDialogHeader: "ベースシフトの更新",
    updateTemplateDialogInstructions:"このベースシフトの詳細を更新してください。",
    addTemplateNameFieldLabel: "ベースシフト名",
    addTemplateHelpText: "重複しないベースシフト名を入力してください",
    addTemplateGetRestaurantsError: "店舗のリストを取得できませんでした",
    addTemplateNoRestaurantsError: "ベースシフトを作成または編集するためには、あなたに店舗が関連付けされていなければなりません。",
    addTemplateSalesTargetFieldLabel: "売上設定",
    addTemplateWeekdayFieldLabel: "平日用",
    addTemplateWeekendFieldLabel: "土曜・日曜・祝日用",
    addTemplateBaseHoursFieldLabel: "考慮時間",
    addTemplateBaseHoursFieldInstructions: "固定、特殊、持帰り考慮を含まないベース考慮時間を設定してください。",
    addTemplateRestaurantFieldLabel: "店舗",
    addTemplateSalesTargetDuplicatedError: "同じ売上設定のベースシフトが既に存在します。",

    //Rostering Edit Page
    rosteringEditAddNewTask: "業務内容の選択",
    rosteringEditSelectStationsOption: "ポジションの選択",
    rosteringEditSelectStationsTitle: "ポジションの選択",
    rosteringEditSelectStationsDescription: "どのポジションが利用できるかを選択してください。",
    rosteringEditMissingStationsMessage: "ポジションのリストを取得できませんでした。再試行してください。",
    rosteringEditAutoFill: "オートフィル",
    rosteringEditInsufficientStaff: "該当者なし",
    rosteringEditPositionsText: (positionList) => positionList.length === 1 ? `${positionList.length}ポジション` : `${positionList.length}ポジション`,
    rosteringEditStartTimeAfterEndTimeMessage: "終了時間は開始時間より後でなければなりません。",
    rosteringEditSelectTasksTitle: "どの業務内容が利用できるかを選択してください。",
    rosteringEditSelectTasksDescription: "ベースシフトに使用する業務内容を選択してください。",
    rosteringEditMissingTasksMessage: "業務内容のリストを取得できませんでした。再試行してください。",
    rosteringEditStationHasTasksError: (stationName) => `関連付けされた業務内容または時間枠があるため、${stationName}を削除できません。`,
    rosteringStateDraft: "非公開",
    rosteringStatePublished: "公開",
    rosteringStateEditing: "編集中",
    rosteringEditSelectTemplate: "ベースシフトを選択",
    rosteringEditStoreTarget: "店舗売上予想",
    rosteringEditHQTarget: "本部売上予想",
    rosteringEditTenThousandYen: "万円",
    rosteringEditAssignSL: "セクション・リーダーにする",
    rosteringEditUnassignSL: "セクション・リーダーから外す",
    rosteringEditDeletePosition: "時間枠を削除",
    rosteringEditMultipleSLError: "セクションリーダーが既に割り当てられています。",
    rosteringEditTaskPopupTitle: "担当する作業",
    rosteringEditTaskPopupNoTasks: "担当する作業がありません。",
    rosteringEditPlatesDropdownTitle: "持帰り皿数",
    rosteringEditNavigateToPreviousRoster: "前日を表示",
    rosteringEditNavigateToNextRoster: "翌日を表示",

    //Print Rosters Dialog
    printRostersDialogTitle: "シフトの印刷",
    printRostersDialogInstructions: "印刷するシフトの期間を指定してください。",

    //Add Roster Dialog
    addRosterGetTemplatesError: "ベースシフトのリストを取得できませんでした。",
    addRosterNoTemplatesError: "この店舗で利用できるベースシフトが見つかりません。",
    addRosterSelectorLabel: "ベースシフトの選択",
    addRosterTitle: (dateToDisplay) => `${dateToDisplay}のシフトを作成`,

    //Roster Error Report
    errorReportTitle: "シフトに対する警告またはエラー",
    errorReportGeneralErrors: "シフトに問題があります。ここをクリックして詳細を確認してください。",
    errorReportExcessivePositions: "勤務可能な従業員数より多くの時間枠があります。",
    errorReportNotPublished: "このシフトはまだ公開されていません。",
    errorReportBeforeStart: (stationName, startTime, earliestTime) => `${stationName}に${startTime}出勤のシフトがあります。出勤時間は${earliestTime}より後でなければなりません。`,
    errorReportAfterEnd: (stationName, endTime, latestTime) => `${stationName}に${endTime}退勤のシフトがあります。退勤時間は${latestTime}より前でなければなりません。`,
    errorReportNoPositions: (stationName) => `${stationName}にはシフトがありません。`,
    errorReportNoTasks: (stationName) => `${stationName}には作業が割り当てられていません。`,
    errorReportNoTaskSlots: (stationName, taskName) => `${stationName}の${taskName}には時間が割り当てられていません。`,
    errorReportNotEnoughBreak: (
        employeeName,
        breakMinutes,
        minBreakMinutes
    ) => `${employeeName}さんには${minBreakMinutes}分以上の休憩が必要なシフトありますが、${breakMinutes}分の休憩しか割り当てられていません。`,
    errorReportNoAppropriateStaff: (taskName,stationName,startTime,endTime) =>  `${stationName}の${startTime}-${endTime}の作業(${taskName})はカバーされていません。`,
    errorReportEmployeeNoAvailability: (employeeName) => `${employeeName}さんはこの日は勤務できません`,
    errorReportEmployeeNotAvailable: (employeeName, start, end) => `${employeeName}さんが出勤できる時間は${start}-${end}です`,
    errorReportInsufficentOvertime: (employeeName, start, end) => `${employeeName}さんが${start}-${end}を勤務すると残業時間の上限を超えます`,
    errorReportEmployeeConsecutiveDayWarning: (employeeName) => `${employeeName}さんの連続勤務日数が5日を超えています`,
    errorReportShiftTooLong: (employeeName, maxHoursPerDay) => `${employeeName}さんは1日${maxHoursPerDay}時間以上勤務できません`,
    errorReportShiftTooLate: (employeeName, latestEndTime) => `${employeeName}さんは${latestEndTime}より遅くまで勤務できません`,
    errorReportHoursPerWeekWarning: (employeeName, start, end, limit) => `${employeeName}さんが${start}-${end}を勤務すると週の勤務時間の上限(${limit}時間)を超えます`,
    errorReportHoursDay: (employeeName, start, end, limit) => `${employeeName}さんが${start}-${end}を勤務すると1日の勤務時間の上限(${limit}時間)を超えます`,

    //Edit Template Page
    editTemplateEditOption: "ベースシフトの詳細を編集",

    //calendar:
    calendarToggleView: "ビューの切り替え",
    caldendarMonths: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
    ],
    days: [
      '日',
      '月',
      '火',
      '水',
      '木',
      '金',
      '土'
    ],
    fullDays: [
        '日曜',
        '月曜',
        '火曜',
        '水曜',
        '木曜',
        '金曜',
        '土曜'
    ],
    shorthandDates: [
        '日',
        '月',
        '火',
        '水',
        '木',
        '金',
        '土'
    ],
    calendarCustomMessages:{
      "today":"今日",
      "previous":"前へ",
      "next":"次へ",
      "month":"月",
      "week":"週",
      "day":"日",
      "agenda":"アジェンダ"
    },
    dateTitle: (date,day,month) => {return `${month}${date}日(${day})`},
    dateTitleShort: (date,day,month) => {return `${month}${date}日 (${day})`},
    dateFormat: (date,day,month,year) => {return `${year}年${month.padStart(3, '0')}${date.toString().padStart(2, '0')}日 (${day})`},
    dateFormat2: (date,day,month,year) => {return `${year}年${month}${date.toString().padStart(2, '0')}日 (${day})`},
    dateFormat3: (date,month,year) => {return `${year}年${month.padStart(3, '0')}${date.toString().padStart(2, '0')}日`},
    dateFormat4: (day,date,month,year) => {return `${year}年${month}月${date}日(${day})`},
    dateFormat5: (day,date,month,year) => {return `${year}年${month}${date}日(${day})`},
    dateFormat6: (startYear, startMonth, startDay, endYear, endMonth, endDay) => {return `${startYear}年${startMonth}${startDay}日 ~ ${endYear}年${endMonth}${endDay}日`},
    dateFormat7: (day,date,month) => {return `${month}月${date}日(${day})`},
    dateFormat8: (startYear, startMonth, startDay) => {return `${startYear}年${startMonth}${startDay}日 ~`},
    dateFormat9: (day, date) => {return `${date}日 (${day})`},
    dateFormat10: (year, month, day) => {return `${year}年${month}${day}日`},
    dateFormat11: (date,month) => {return `${month}月${date}日`},
    timestamp: (date) => {return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`},

    //Staff Page
    staffSkillsTab: "スキル",
    staffSkillsMappingTab: "スキルと研修項目の関係",
    staffAvailabilityTab: "出勤可能日",
    staffSettingsTab: "設定",
    staffSettingsContractTypeHeader: "契約の種類",
    staffAvailabilityPatternTab: "出勤可能日(パターン)",
    staffAvailabilityBusySeasonTab: "繁忙期",
    staffAvailabilityModificationTab: "変更",
    staffTimeOffTab: "休暇",
    staffAvailabilityObon: "お盆",
    staffAvailabilityNewYear: "年末年始",

    //Reset Password
    resetPassword: "パスワードのリセット",
    resetPasswordConfirm: "このパスワードをリセットしてよろしいですか？",

    //Availability
    staffAvailability: "出勤可能日",
    staffAvailabilityDay: "日付",
    staffAvailabilityStart: "出勤",
    staffAvailabilityEnd: "退勤",
    staffAvailabilityTotalHours: "勤務時間",
    staffAvailabilityTableHeader: "出勤可能日の追加",
    staffAvailabilityStartTimeLabel: "出勤",
    staffAvailabilityEndTimeLabel: "退勤",
    staffAvailabilityDateLabel: "日付",
    staffAvailabilityAddAvailabilityCard: "出勤可能時間の追加",
    staffAvailabilityEditAvailabilityCard: "出勤可能時間の編集",
    staffAvailabilityTimesError: "出勤時間は退勤時間より前でなければなりません",
    staffAvailabilityInvalidDate: "日付が指定されていません",
    staffAvailabilityInvalidStart: "出勤時間が指定されていません",
    staffAvailabilityInvalidEnd: "退勤時間が指定されていません",
    staffAvailabilityOverlapError: "重複しない出勤可能日時を指定してください",
    staffAvailabilityPatternInstructions: "出勤可能時間を指定してください。",
    staffAvailabilityModificationInstructions: "出勤可能日(パターン)に対する変更を指定してください。",
    staffAvailabilityStartTimeError: (time) => `${time}より前の出勤時間を入力できません。`,
    staffAvailabilityEndTimeError: (time) => `${time}より後の出勤時間を入力できません。`,
    staffAvailabilityStartEndValidation: "出勤時間はたい退勤時間より前でなければなりません。",
    staffAvailabilityApproved: "承認",
    staffAvailabilityApplied: "適用済",
    staffAvailabilityRejected: "差戻し",
    staffAvailabilityPending: "承認待ち",
    staffAvailabilityAlreadyApplied: "この期間の出勤可能予定は適用済で、変更できません。",
    staffAvailabilityApplyButton: "申請",
    staffAvailabilityAppliedNotification: "出勤可能日が申請されました。",
    staffAvailabilityErrorNotification: "出勤可能日を申請できませんでした。",
    staffAvailabilityMaxDaysPerWeek: "出勤可能日数",

    //Availability Modification
    availabilityModificationSetUnavail: "勤務なし",
    availabilityModificationSetAvailTimes: "勤務可能時間の設定",
    availabilityModificationAddTitle: "変更の登録",
    availabilityModificationAddAdditionalDaysAndTimeOffsMessage: "出勤予定日の変更を申請してください。申請が承認され、シフト作成に適用されると、申請は変更できなくなります。",
    availabilityModificationAddTimeOffsMessage: "休暇を申請してください。申請が承認され、シフト作成に適用されると、申請は変更できなくなります。",
    availabilityModificationNotAvailable: "出勤取消",
    availabilityModificationsNoModifications: "現在、変更は登録されていません。",
    availabilityModificationsStartTimeError: (time) => `${time}より前の出勤時間を入力できません。`,
    availabilityModificationsEndTimeError: (time) => `${time}より後の出勤時間を入力できません。`,
    availabilityModificationsStartEndValidation: "出勤時間はたい退勤時間より前でなければなりません。",
    availabilityModificationsNoPatternToCancel: "この日は勤務予定ではないため、予定変更の必要はありません。あるいは、出勤可能日(パターン)が承認されていません。",
    availabilityModificationsAlreadyRequested: "すでに同じ申請が提出されています。",
    availabilityModificationsDeadlinesTitle: "締切り",

    //Availability Time Off
    availabilityTimeOffAddButtonLabel: "休暇申請の追加",

    //Availability Review
    availabilityReviewPatternHeader: "出勤可能日(パターン)",
    availabilityReviewExceptionHeader: "出勤可能日の変更・取り消し",
    availabilityReviewPrint: "印刷",
    availabilityReviewEditInstructions: "出勤可能日を変更してください。",
    availabilityReviewExceptionEditInstructions: "勤務可能時間を変更してください。",
    availabilityReviewExceptionEditInstructions2: "申請が承認されていません。勤務可能時間を確定してください。",

    //Staff Skills
    skillsNameHeader: "名前",
    skillsRatingHeader: "評価",
    staffSkillsDialogAdd: "スキルの追加",
    
    //Station Selector
    stationSelectorAllStations: "全ポジション",

    //Staff Overtime
    staffOvertimeCardHeader: "月毎の残業時間",
    staffOvertimeTabHeader: "残業時間",
    staffOvertimeImportButton: "インポート",
    staffOvertimeImportHeader: "社員・従業員の残業時間データをアップロード",

    //Staff school holiday
    staffSchoolHolidayCardTitle: "長期休暇の登録",
    staffSchoolHolidayModalTitle: "長期休暇の登録",
    staffSchoolHolidayModalInstructions: "長期休暇の期間を指定してください。",
    staffSchoolHolidayStart: "開始",
    staffSchoolHolidayEnd: "終了",
    staffSchoolHolidayPeriod: (start, end) => `休暇 (${start} ~ ${end})`,

    //Batch Optimisation
    batchOptimiseAddButton: "実行",
    batchOptimiseInstructions: "勤務シフトを自動作成するバックグラウンド・ジョブを追加します。店舗と勤務シフトの対象期間を指定してください。",
    batchOptimiseFrom: "開始",
    batchOptimiseTo: "終了",
    batchJobStartedSuccessfully: "勤務シフトの自動作成処理が開始されました",

    //Checkbox
    selectAll: "すべてを選択",
    selectAllResults: "すべての検索結果を選択",

    //Schedule
    scheduleNoPositionsError: "スケジュールを見るためには、少なくとも1店舗に所属している必要があります。",
    scheduleIconHoverAdd: "スケジュールの追加",
    scheduleIconHoverEdit: "スケジュールの編集",

    //Shift Time Picker
    shiftTimeLabelStart: "開始",
    shiftTimeLabelEnd: "終了",

    //Working Limits
    workingLimitsTitle: "勤務制限",
    workingLimitsCategories: "カテゴリー",
    workingLimitsFullTime: "フルタイム",
    workingLimitsPartTime: "パート",
    workingLimitsRegular: "一般",
    workingLimitsHighschoolStudent: "高校生",
    workingLimitsCasual: "カジュアル",
    workingLimitsForeignWorker: (highSchooler) => highSchooler ? "外国人(高校生)": "外国人",
    workingLimitsForeignStudent: (highSchooler) => highSchooler ? "外国人留学生(高校生)" : "外国人留学生",
    workingLimitsDay: "日",
    workingLimitsWeek: "週",
    workingLimitsOvertime: "残業",
    workingLimitsCloseOfShop: "閉店",
    workingLimitsCloseOfBusiness: "閉店+ラスト残業",
    workingLimitsLateWorkerModification: "ラスト契約",
    workingLimitsTermTimeModificiation: "長期休暇中",
    workingLimitsHoursPerDay: (hours) => `勤務可能時間: ${hours}`,
    workingLimitsMaxHoursPerDay: (hours) => `勤務時間の上限(時間): ${hours}`,
    workingLimitsWorkHours: (range) => `勤務時間制限: ${range}`,
    workingLimitsHoursPerWeek: (hours) => `勤務可能時間: ${hours}`,
    workingLimitsDaysPerWeek: (days) => `最大勤務可能日数: ${days}`,
    workingLimitsContinuousDays: (days) => `最大連続勤務可能日数: ${days}`,
    workingLimitsOvertimeHoursPerMonth: (hours) => `1ヶ月の残業時間: ${hours}`,

    //Deadlines
    availabilityPeriod: "期間",
    availabilityPeriodAmendmentStart: "申請開始",
    availabilityPeriodAmendmentEnd: "申請期限",
    availabilityPeriodModalTitle: "出勤可能日(パターン)の締切り",
    availabilityPeriodCardTitle: "出勤可能日(パターン)の締切り",
    availabilityPeriodTargetPeriod: "対象期間: ",
    availabilityPeriodNotFound: "出勤可能日(パターン)の期間が定義されていないため、新規申請はできません。",
    availabilityPeriodFailedToDelete: (period) => `${period}は使用中です。削除できません。`,
    availabilityExceptionPeriod: "期間",
    availabilityExceptionPeriodDeadline: "申請期限",
    availabilityExceptionPeriodModalTitle: "出勤可能日変更の締切り",
    availabilityExceptionPeriodCardTitle: "出勤可能日変更の締切り",
    availabilityExceptionPeriodNotFound: "出勤可能日変更の期間が定義されていないため、新規申請はできません。",

    //Breaks
    breaksPageTitle: "休憩時間",
    breaksLowerHours: "勤務時間(から)",
    breaksUpperHours: "勤務時間(まで)",
    breaksDurationMinutes: "時間 (分)",
    breaksBreakRules: "休憩ルール",
    breaksMinTimeFromStartString: (number) => `勤務開始から休憩までの最短時間: ${number} ${number === 1 ? "時間" : "時間"}`,
    breaksPreferredSmallestBreakString: (number) => `休憩を分割する時の最小単位: ${number} ${number === 1 ? "分" : "分"}`,
    breaksMinTimeFromEndString: (number) => `休憩から勤務終了までの最短時間: ${number} ${number === 1 ? "時間" : "時間"}`,
    breakStartAfterEndError: "終了時間は開始時間より前に設定できません。",
    breakInvalidStartTimeError: "開始時間は勤務時間外に設定できません。",
    breakInvalidEndTimeError: "終了時間は勤務時間外に設定できません。",

    //WebSocket
    webSocketConnectionUnknownStatus: "接続ステータス: 不明",
    webSocketConnectionStats: "接続ステータス:",
    webSocketConnectionConnecting: "接続中",
    webSocketConnectionOpen: "接続",
    webSocketConnectionClosing:"切断中",
    webSocketConnectionClosed:"切断",
    webSocketConnectionUninstantiated:"未確立",
    webSocketAutoFill: "オートフィル",

    //Upload staff Availability
    availabilityUploadFile: "出勤可能一覧のアップロード",
    availabilitySelectType: "データ種類の選択",
    availabilityRadioPattern: "出勤可能一覧",
    availabilityRadioExceptions: "特別予定一覧",
    availabilityChooseFile: "ファイル選択",
    availabilityUploadSuccess: "ファイルは正常にアップロードされました",
    availabilityImportStaffButton: "スタッフ出勤予定のインポート",

    //Download staff Availability
    availabilityExportPreferredAvailability: "従業員出勤予定のエクスポート",
    availabilityExportAvailabilityModifications: "従業員出勤予定変更のエクスポート",
    availabilityPreferredFileName: "従業員出勤予定",
    availabilityModificationsFileName: "従業員出勤予定変更",

    //useContactData
    contactDataLastHourWorker: "ラスト契約",
    contactDataForeigner: "外国人",
    contactDataHighSchooler: "高校生",
    contactDataSummerBreak: "夏季休暇",
    contactDataStartDate: "開始",
    contactDataEndDate: "終了",
    contactDataTo: "〜",

    //Roster optimisation problems
    rosterValidationCriticalNoStations: "ポジションが存在しません。シフトに少なくとも1つのポジションを追加してください。",
    rosterValidationCriticalNoStaff: "シフトに配置できるスタッフがいません。",
    rosterValidationCriticalNoTasks: "シフトに作業が設定されていません。各ポジションに少なくとも1つの作業を追加してください。",
    rosterValidationCriticalNoTimeSlots: "シフトに作業時間枠が設定されていません。各ポジションに少なくとも1つの作業時間枠を追加してください。",
    rosterValidationNoWorkToDo: "スタッフを配置できませんでした。すべてのポジションはスタッフは配置済です。",
    rosterValidationInsufficientStaff: "スタッフの人数が十分でなく、すべてのポジションに配置できません。シフト作成は完了しません。",

    //Confirmation Prompt Text
    confirmationFileMessage: "このファイルを本当にアップロードしてよろしいですか？既存のデータはすべて上書きされます。",
    confirmationCancel: "キャンセル",
    confirmationAccept: "はい",

    //Delete Roster
    deleteRoster: "シフトの削除",
    deleteRosterConfirmation: "このシフトを削除してよろしいですか？",

    //Print Roster
    printRoster: "シフトの印刷",

    //Staff Health Check Roster
    healthCheckOption: "従業員 体調記録表",

    //Health Check
    healthCheckList: "体調記録表",
    healthCheckListStoreTemp: "体温 (あいさつ時)",
    healthCheckListHomeTemp: "体温 (自宅)",
    healthCheckCough: "うがい、コロコロをしているか（湯呑とコロコロの紙で確認）",
    healthCheckFatigue: "下痢・嘔吐・腹痛・吐き気がないか",
    healthCheckLossOfTasteOrSmell: "同居のご家族にも同様の症状がないか",
    healthCheck3rdPartyInfection: "携帯・スマホを持っていないか（金属探知機で確認）",
    healthCheckAbdominalPain: "店番長を確認したか",
    healthCheckMobileBan: "キャンペーン時は、新商品を確認しているか",
    healthCheckInfection: "フロアの片付け担当1と片付け担当2はハンディの名前があっているか確認",
    healthCheckYes: "はい",
    healthCheckNo: "いいえ",
    healthCheckYesNo: "(はい/いいえ)",
    healthCheckTemperatureUnit1: "°C",
    healthCheckTemperatureUnit2: "(°C)",

    //Change Roster Template
    changeTemplatePrompt: "ベースシフトを変更してよろしいですか？現在のデータは削除されます。",
    changeTemplateReplace: "置き換え",
    changeTemplateCancel: "キャンセル",

    //Template Header Content
    templateHeaderSalesTarget: "売上予想",

    //Roster status
    rosterEditing: "編集中",
    rosterPublished: "公開",
    rosterRepublished: "再公開",

    //Publish toggle
    rosterPublish: "公開",

    //Template Add Roster
    templateThousandYen: "万円",
    templateNoTemplateTarget: "(売上予想なし)",
    templateStoreTarget: "店舗売上予想",
    templateSelectByTarget: "売上予想に基づいてベースシフトを選択してください。",
    templateHQTarget: "本部売上予想",
    templateNotSet: "未設定",
    templateTargetSetByHQ: "本部で設定された売上予想",
    templateSingular: "ベースシフト",

    //Shifts
    shiftsYourNextShifts: "次の勤務シフト",
    shiftsNoneAssignedError: "割当てられた勤務シフトがありません。",
    shiftsBreak: (length, time) => `${time}に${length}分の休憩`,
    shiftsUnpublished: "非公開",

    //Roster Summary
    rosterSummaryAverageWage: "平均時給(円)",
    rosterSummaryDailySalary: "社員日給(円)",
    rosterSummaryExpenseRatio: "人件費率(%)",
    rosterSummaryTotalHours: "実働時間(時間)",
    rosterSummaryBaseHours: "規定時間",
    rosterSummaryExcess: "超過",
    rosterSummaryInsufficient: "不足",
    rosterSummaryTotalBreakMinutes: "総休憩(分)",
    rosterSummaryPersonnelCount: "合計時間",
    rosterSummaryExpPersonnelCount: "設定時間",
    rosterSummaryAfterHours: "ラスト残業(分)",

    //Targets page
    targetsPageTitle: "過不足状況表",
    targetsNoTemplateError: (target) => `売上予想${target}用のベースシフトが、見つかりませんでした。`,
    targetsStoreHeader: "店舗",
    targetsStoreNameHeader: "店名",
    targetsSettingsHeader: "項目",
    targetsNotSet: "未設定",
    targetsNA: "N/A",
    targetsHqSales: "本部売上予想",
    targetsStoreSales: "店舗売上予想",
    targetsProportionalHours: "特殊考慮",
    targetsFixedHours: "固定考慮",
    targetsPlates: "持帰り皿数",
    targetsDatePickerLabel: "表示開始の日付を選択",
    targetUploadFileDialogLabel: "売上予想と考慮データのアップロード",
    targetUploadCSVButton: "インポート",
    targetsNoAssignedRestaurantsError: "売上予想を見るためには、管理対象に少なくとも1店舗が含まれていなければなりません。",
    targetsAssigned: "シフト人数",
    targetsPreferred: "目安人数",
    targetsDifference: "差異",
    noSalesTargetsFileSelected: "売上予想が選択されていません",
    noFixedExtraHoursFileSelected: "1日あたりの考慮が選択されていません",
    noProportionalExtraHoursFileSelected: "100万円あたりの考慮が選択されていません",

    //Slider
    sliderStart: "開始",
    sliderOpen: "開店",
    sliderClose: "閉店",
    sliderEnd: "終了",

    //Fixed Positions
    fixedPositions: "固定シフト",
    editFixedPositionsTitle: "曜日でシフトを固定",
    editFixedPositionsInstructions: "従業員に対して固定シフトを割り当ててください。",
    editFixedPositionsHeaderDay: "曜日",
    editFixedPositionsHeaderEnable: "有効",
    editFixedPositionsHeaderStation: "ポジション",
    editFixedPositionsHeaderTime: "時間",
    editFixedPositionsNoStationsError: "固定シフトを設定する前にポジションが必要です。",
    editFixedPositionsStartEndError: "出勤時間はたい退勤時間より前でなければなりません。",
    createFixedPositionHeader: "ユーザーを固定シフトに追加",
    createFixedPositionInstructions: "固定シフトに追加する従業員を選択してください。",
    createFixedPositionLocationFieldLabel: "店舗",
    createFixedPositionStaffFieldLabel: "従業員",
    editFixedPositionsHeaderTotal: "合計時間",
    deleteFixedPositionSingular: (name: string) => `${name}さんの固定シフトを削除してもよろしいですか？`,
    deleteFixedPositionPlural: (count: number) => `${count}個の固定シフトを削除してもよろしいですか？`,
    editFixedPositionTabPattern: "固定シフト(曜日パターン)",
    editFixedPositionTabExceptions: "固定シフト(日付)",
    editFixedPositionExceptionsHeading: "日付でシフトを固定",
    editFixedPositionExceptionsDescription: "従業員の固定シフトを設定してください。ここでの指定は曜日指定より優先されます。",
    editFixedPositionExceptionsHeaderDate: "日付",
    editFixedPositionExceptionsHeaderException: "固定シフト",
    editFixedPositionExceptionsHeaderTime: "時間",
    editFixedPositionExceptionsRemoved: "曜日パターンから除外",
    addFixedPositionExceptionsTitle: "日付による固定シフト",
    addFixedPositionExceptionsSubtitle: "日付を指定し、新しいシフト時間を設定するか、取り消しを行います。曜日パターンは上書きされます。",
    addFixedPositionExceptionsSelectDate: "日付の選択",
    addFixedPositionExceptionsRemoveDate: "この日のシフトを取り消す",
    addFixedPositionExceptionsSetStation: "この日のポジションと時間を指定",
    addFixedPositionExceptionsInvalidStationList: "ポジションのリストを取得できませんでした",
    addFixedPositionExceptionsStartTimeAfterEndTimeMessage: "終了時間は開始時間より後でなければなりません。",
    addFixedPositionExceptionsAlreadyExists: "この日の固定シフトは既に割り当てられています。",
    employeeTimeSlotStartTimeAfterEndTimeMessage: "開始時間は終了時間より前でなければなりません。",
    employeeTimeSlotEndTimeBeforeStartTimeMessage: "終了時間は開始時間より後でなければなりません。",
    employeeTimeSlotTooLong: (duration) => {return `${duration}時間以上は勤務できません`},

    // Import Errors
    importEmployeesEmptyIDErr: "従業員番号は必ず必要です。",
    importEmployeesIDLengthErr: "従業員番号は7桁以内です。",
    importEmployeesFirstLastEmptyName: "姓・名は必ず必要です。",
    importEmployeesInvalidLeaderVal: "現PAﾒｲﾄﾘｰﾀリーダー区分ＣＤが不正です。",
    importEmployeesInvalidCourseVal: "現PAｺｰｽ時 コース外略が不正です。",
    importEmployeesInvalidEmployeeRole: "現在役職コードが不正です。",
    importSenManagerDuplicateStoreID: "店舗番号または店舗名に重複があります。",
    importSenManagerEmployeeNotFound: (id, name) => {return `従業員 ${id}:${name}が見つかりません。`},
    importSenManagerDuplicateEmployeeID: "重複した従業員番号/Emailアドレスが見つかりました。",
    importAvailabilityDataMissing: (index) => {return `${index}行目: 列データが不十分です。`},
    importAvailabilityMonthDurationErr: (months) => {return `期間(${months})は1以上6以下でなければなりません`},
    importAvailabilityInvalidMonth: (month) => {return `${month}月は不正です`},
    importAvailabilityExceptPlanTypeInvalid: (name, id) => {return `従業員 ${name}(${id}): 申請タイプが不正です。`},
    importAvailabilityExceptTimeNotSet: (name, id) => {return `従業員 ${name}(${id}): 新しい予定が設定されていません。`},
    importTargetsMissingDate: "日付が抜けています。",
    importTargetsInvalidDate: (date) => {return `'${date}'は正しい書式の日付ではありません。`},
    importTargetsLocationError: (locationNumber) => {return `店舗${locationNumber}が見つかりません。`},
    importTargetsTemplateError: (locationName) => {return `${locationName}店には売上予想に対応するベースシフトが存在しません。`},
    importLocationsDuplicateID: "重複する店舗が見つかりました。",
    importTemplatesSheetTargetMatchErr: "シート名と売上予測値(A2セル)は一致しなければなりません。",
    importTemplatesBaseMasterPatternErr: "ベースシフトには平日と土日祝日用の作業内容シートが含まれていなければなりません。",
    importTemplatesBaseMasterTemplateErr: "ベースシフトは1店舗用でなければなりません。",
    importTemplatesLocationDoesntExist: (id) => {return `店舗(${id})が見つかりません。`},
    importTemplatesBaseMasterLocationIdErr: (id) => {return `店舗(${id})に対するベースシストをアップロードする権限がありません。`},
    importTemplatesLocationIDerr: (id) => {return `店舗${id}が見つかりません。`},
    importTemplatesTaskDoesntExist: (task) => {return `作業内容(${task})がシステムに定義されていません。`},
    importTemplatesStationDoesntExist: (station) => {return `ポジション(${station})がシステムに定義されていません。`},
    importAccountInfoMissing: (employeeIds) => {return `次の従業員情報がアカウント一覧に存在しません。アカウント一覧を更新し、再試行してください。[${employeeIds}]`},

    //No staff error
    noStaffError: "店舗に従業員が登録されていません。",

    //Overtime
    overtime: "残業時間",
    monthlyOvertime: "月別 残業時間",
    monthlyOvertimeNameHeader: "氏名",

    //BatchOptimisation
    batchOptimisation: "シフト自動作成",
    batchOptimisationNameHeader: "店舗",

    //Rostered Staff Review
    rosteredStaff: "店舗張出シフト",
    rosteredStaffPrintTitle: (pageNumber: number, pageTotal: number) => `店舗張出シフト - ${pageNumber} / ${pageTotal}`,
    rosteredStaffNameHeader: "従業員",
    rosteredStaffCheckColumnHeader: "確認欄",

    //Daily Duties
    fullTimeEmployeeShiftHeader: "名前",
    stationDutyHeader: "時間帯",
    dailyDuties: "担当者一覧表",
    dailyDutySectionHeaderManager: "店長",
    dailyDutySectionHeaderFullTimeEmployee: "社員",
    dailyDutySectionHeaderGeneral: "統括リーダー",
    dailyDutySectionHeaderBackyard: "バックヤード",
    dailyDutySectionHeaderNigiri: "にぎり",
    dailyDutySectionHeaderGunkan: "ぐんかん",
    dailyDutySectionHeaderFloor: "フロアー",
    dailyDutySectionHeaderCashier: "レジ担当者",
    dailyDutySectionHeaderCleanliness: "クリンネス担当",
    timeWindow0914: "9:00-14:00",
    timeWindow1417: "14:00-17:00",
    timeWindow1720: "17:00-20:00",
    timeWindow2023: "20:00-23:00",
    morningSectionLeader: "朝SL",
    nightSectionLeader: "夜SL",
    dailyDutyPrintTitle: (pageNumber: number, pageTotal: number) => `担当者一覧表 - ${pageNumber} / ${pageTotal}`,
    dailyDutyNotSelected: "なし",

    //Common
    fileDownloadedSuccessfully: "ファイルのダウンロードが完了しました",
    dateRangeFrom: "開始",
    dateRangeTo: "終了",

    //Availability Review
    availabilityReview: "出勤可能日の申請",
    availabilityReviewNameHeader: "従業員",
    availabilityReviewMaxDaysPerWeekHeader: "出勤可能日数",
    availabilityReviewRemoved: "出勤予定取消し",
    availabilityReviewApprove: "承認",
    availabilityReviewApproved: "承認済",
    availabilityReviewDeny: "差戻し",
    availabilityReviewDenied: "差戻し済",
    availabilityReviewApplied: "適用済",
    availabilityReviewConformation: "本当に申請を差し戻してよろしいですか？",
    availabilityReviewPrintOff: "勤務なし",
    availabilityReviewPrintTitle: (pageNumber: number, pageTotal: number) => `出勤可能日 - ${pageNumber} / ${pageTotal}`,
    availabilityReviewConfirmButton: "はい",
    availabilityReviewApplyButton: "適用",
    availabilityReviewConfirmApply: "これらの変更をシフト作成時の従業員出勤可能日として適用しますが、よろしいですか？",
    availabilityReviewUnapprovedExceptionsError: "未承認の出勤可能日の変更・取り消し申請があります",
    availabilityReviewUnapprovedPatternsError: "未承認の出勤可能日(パターン)申請があります",

    locationPickerUnassigned: "配属なし",

    //Training
    training: "教育チェックシート",
    trainingSetup: "教育チェックシートの編集",
    trainingName: "スキル名",
    trainingNoDataError: "このポジションには研修データがありません",
    trainingEditItem: "研修項目の編集",
    trainingAddItem: "研修項目の編集",
    trainingUpload: "教育チェックシートのアップロード",
    trainingDownloadSuccess: "ファイルは正常にダウンロードされました",
    trainingImport: "記入済の教育チェックシートをインポート",
    trainingExport: "教育チェックシートの書式をダウンロード",

    //Global Search
    globalSearchPrompt: "検索文字列を入力してください",
    globalSearchToggle: "全店舗を検索",
    searchNoResultsError: (searchTerm) => `'${searchTerm}'と一致するユーザーが見つかりませんでした。`,

    //Training Category
    trainingCategoryDelete: "削除",
    trainingCategoryEditItem: "編集",
    trainingCategoryAddCategory: "新しいカテゴリーの追加",
    trainingCategoryEditCategory: "カテゴリーの編集",
    trainingCategoryAddItem: "新しい研修項目の追加",
    defaultTrainingItemName: "新規研修項目"
};